import { Atom, Cmp, SHARED_UTILS, state } from ":mods";
import { For, Show, createEffect, splitProps } from "solid-js";
import { SideNavAccordionItem, SideViewProps } from "../model";
import "../style/nav-side.css";

export function Side(props: SideViewProps) {
  const [local, other] = splitProps(props, ["base", "items", "layer", "status", "class"]);
  const $status = state.createMemo(() => local.status);
  return (
    <nav
      {...other}
      class={`
    ${local.class ?? ""} 
    ${$status() === "hide" ? " children:!invisible " : ""}
    `}
    >
      {/* navs */}
      <div class="anchor-group">
        <Show when={!local.layer} fallback={local.layer}>
          <For each={local.items}>
            {(item) => {
              if (typeof item === "function") {
                return (
                  <>
                    {item({
                      actions: {
                        ...Cmp.Alert.actions,
                        ...Cmp.Popup.actions,
                        ...Atom.Route.actions,
                      },
                      base: local.base,
                      classes: { anchor: "anchor" },
                    })}
                  </>
                );
              }
              if (Array.isArray(item)) {
                const [title, ...items] = item;
                const options: SideNavAccordionItem[0] = typeof title === "string" ? { title: title } : title;
                return (
                  <Atom.Foldable.Accordion
                    title={options.title}
                    class="accordion"
                    groupClass="group"
                    titleClass="title"
                    immediateLoad={options.immediateLoad}
                    dontCollapseOnOutsideClick
                  >
                    <For each={items}>
                      {(item) => {
                        const to = item.to && SHARED_UTILS.mergeRouteHref({ base: local.base }, item.to);
                        // console.log("anchor group arr :: ", to);
                        return (
                          <Atom.Route.Anchor
                            {...item}
                            to={to}
                            class={`anchor ${item.class ?? ""} `}
                            activeClass="active"
                          />
                        );
                      }}
                    </For>
                  </Atom.Foldable.Accordion>
                );
              }
              const to = item.to && SHARED_UTILS.mergeRouteHref({ base: local.base }, item.to);
              // console.log("anchor group :: ", to);
              return <Atom.Route.Anchor {...item} to={to} class={`anchor ${item.class ?? ""} `} activeClass="active" />;
            }}
          </For>
        </Show>
      </div>
    </nav>
  );
}
